<template>
  <div>
    <div class="py-6" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
    </div>
    <div class="bg-white shadow-sm">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="d-md-flex align-items-center justify-content-between bg-white  pt-3 pb-3 pb-lg-5">
              <div class="d-md-flex align-items-center text-lg-start text-center ">
                <div class="me-3 mt-n8">
                  <router-link :to="{name: 'Home'}" :title="school.name">
                    <img :src="school.thumbnail ? school.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="school.name"
                         class="avatar-xxl rounded bg-white object-fit-cover">
                  </router-link>
                </div>
                <div class="mt-3 mt-md-0">
                  <h1 class="mb-0 fw-bold me-3 ">{{ school.name }}</h1>
                  <div>
                    <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{userSchoolCount.admin}}</span> admin</span>
                    <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{userSchoolCount.teacher}}</span> giáo viên</span>
                    <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{userSchoolCount.pupil}}</span> học viên</span>
                    <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{school.courses.paginatorInfo.total }}</span> khóa học</span>
                    <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{submits.paginatorInfo.total }}</span> bài nộp</span>
                  </div>
                </div>
              </div>
              <div class="mt-3 mt-lg-0 text-lg-start text-center">
                <button v-if="tab === 'about'" data-bs-toggle="modal" data-bs-target="#updateSchoolModal"
                        class="btn btn-primary float-end text-nowrap"><i class="fe fe-edit-2"></i> Sửa thông tin
                </button>
                <router-link v-if="tab === 'course'" class="btn btn-primary float-end text-nowrap"
                             :to="{name: 'AdminCourseAdd'}"><i class="fe fe-plus-circle"></i> Thêm khóa học
                </router-link>
                <router-link v-if="tab === 'combo'" class="btn btn-primary float-end text-nowrap" :to="{name: 'AdminComboAdd'}"><i
                    class="fe fe-plus-circle"></i> Thêm combo
                </router-link>
                <router-link v-if="tab === 'page'" class="btn btn-primary float-end text-nowrap" :to="{name: 'AdminPageAdd'}"><i
                    class="fe fe-plus-circle"></i> Thêm trang
                </router-link>
                <button v-if="tab === 'user'" v-on:click="setRole('pupil')" data-bs-toggle="modal" data-bs-target="#addUserSchoolModal"
                        class="btn btn-primary float-end text-nowrap"><i class="fe fe-plus-circle"></i> Thêm người dùng
                </button>
              </div>
            </div>
            <!-- Nav tab -->
            <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('about')" :class="tab === 'about' ? 'nav-link active' : 'nav-link'"
                   id="pills-about-tab" data-bs-toggle="pill" href="#pills-about" role="tab"
                   aria-controls="pills-about" aria-selected="false">Giới thiệu</a>
              </li>
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('combo')" :class="tab === 'combo' ? 'nav-link active' : 'nav-link'"
                   id="pills-combo-tab" data-bs-toggle="pill" href="#pills-combo" role="tab"
                   aria-controls="pills-combo" aria-selected="true">Combo </a>
              </li>
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('course')" :class="tab === 'course' ? 'nav-link active' : 'nav-link'"
                   id="pills-course-tab" data-bs-toggle="pill" href="#pills-course" role="tab"
                   aria-controls="pills-course" aria-selected="true">Khóa học </a>
              </li>
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('submit')" :class="tab === 'submit' ? 'nav-link active' : 'nav-link'"
                   id="pills-submit-tab" data-bs-toggle="pill" href="#pills-submit" role="tab"
                   aria-controls="pills-submit" aria-selected="true">Bài nộp</a>
              </li>
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('user')" :class="tab === 'user' ? 'nav-link active' : 'nav-link'"
                   id="pills-user-tab" data-bs-toggle="pill" href="#pills-user" role="tab"
                   aria-controls="pills-user" aria-selected="false">Người dùng</a>
              </li>
              <li class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('page')" :class="tab === 'page' ? 'nav-link active' : 'nav-link'"
                   id="pills-page-tab" data-bs-toggle="pill" href="#pills-page" role="tab"
                   aria-controls="pills-page" aria-selected="false">Trang</a>
              </li>
              <li v-if="isAdmin" class="nav-item ms-0" role="presentation">
                <a v-on:click="setTab('setting')" :class="tab === 'setting' ? 'nav-link active' : 'nav-link'"
                   id="pills-setting-tab" data-bs-toggle="pill" href="#pills-setting" role="tab"
                   aria-controls="pills-user" aria-selected="false">Cài đặt</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="modal fade" id="avatarModal" tabindex="-1" role="dialog" aria-labelledby="avatarModalLabel"
             aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="avatarModalLabel">
                  {{ school.name }}
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
                </button>
              </div>
              <div class="modal-body" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" :alt="school.name" class="w-100"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content  -->
    <div class="py-4">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- Tab content -->
            <div class="tab-content" id="pills-tabContent">
              <div :class="tab === 'about' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-about"
                   role="tabpanel" aria-labelledby="pills-about-tab">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="card ">
                      <div class="card-body">
                        <div v-html="school.content"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" id="updateSchoolModal" tabindex="-1" role="dialog"
                     aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-lg">
                    <form v-on:submit.prevent="updateSchool">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">
                            Sửa thông tin trường
                          </h4>
                          <button type="button" id="updateSchoolModalClose" class="btn-close" data-bs-dismiss="modal"
                                  aria-label="Close">
                            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-md-8">
                              <div class="mb-3">
                                <label for="name" class="form-label">Tên trường</label>
                                <input v-model="school.name" id="name" class="form-control" type="text" required/>
                              </div>
                              <div class="mb-3">
                                <label class="form-label">Giới thiệu</label>
                                <Editor v-model:content="school.content"/>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <Thumbnail purpose="setThumbnailForSchool" v-on:setThumbnail="handleSetThumbnailEvent" :thumbnail="school.thumbnail"/>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer justify-content-center">
                          <button class="btn btn-primary" type="submit">Cập nhật</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div :class="tab === 'combo' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-combo"
                   role="tabpanel" aria-labelledby="pills-combo-tab">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <table class="table list">
                          <thead class="table-light">
                          <tr>
                            <th scope="col">Tên combo</th>
                            <th scope="col">Hiện trang chủ?</th>
                            <th scope="col">Vị trí trang chủ</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(combo, index) in school.combos.data" :key="'combo' + index">
                            <td class="align-middle">
                              <div class="d-lg-flex align-items-center">
                                <div class="thumbnail">
                                  <router-link class="text-inherit" :to="{name: 'AdminComboEdit', params: {code: combo.code}}">
                                    <img :src="combo.thumbnail ? combo.thumbnail.link : '/assets/images/brand/logo/erobo.jpg'" alt="" class="rounded">
                                  </router-link>
                                </div>
                                <div class="ms-lg-3 mt-2 mt-lg-0">
                                  <h4 class="mb-1 text-primary-hover">
                                    <router-link class="text-inherit" :to="{name: 'AdminComboEdit', params: {code: combo.code}}">
                                      {{ combo.name }}
                                    </router-link>
                                  </h4>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle">
                              <div class="form-check form-switch">
                                <input type="checkbox" v-if="combo.show_homepage === 1" checked class="form-check-input" :id="'showHomepage' + combo.id">
                                <input type="checkbox" v-if="combo.show_homepage === 0" class="form-check-input" :id="'showHomepage' + combo.id">
                                <label class="form-check-label" :for="'showHomepage' + combo.id"></label>
                              </div>
                            </td>
                            <td class="align-middle">{{ combo.homepage_order }}</td>
                            <td class="align-middle">
                              <span class="badge rounded-pill bg-success" v-if="combo.status === 1">Đang hoạt động</span>
                              <span class="badge rounded-pill bg-danger" v-if="combo.status === 0">Tạm dừng</span>
                            </td>
                            <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="comboDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="comboDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                 <router-link class="dropdown-item" :to="{name: 'AdminComboDetail', params: {code: combo.code}}">
                                    <i class="fe fe-settings dropdown-item-icon"></i> Quản trị
                                  </router-link>
                                  <router-link class="dropdown-item" :to="{name: 'ComboDetail', params: {code: combo.code}}">
                                    <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                  </router-link>
                                <router-link class="dropdown-item" :to="{name: 'AdminComboEdit', params: {code: combo.code}}">
                                    <i class="fe fe-edit dropdown-item-icon"></i> Sửa
                                  </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentCombo(combo)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeComboModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i> Xóa</a>
                              </span>
                            </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <v-pagination
                            v-if="school.combos.paginatorInfo.lastPage > 1"
                            v-model="school.combos.paginatorInfo.currentPage"
                            :pages="school.combos.paginatorInfo.lastPage"
                            :range-size="1"
                            active-color="#DCEDFF"
                            @update:modelValue="loadSchool"
                        />

                        <div class="modal fade" id="removeComboModal" tabindex="-1" role="dialog"
                             aria-labelledby="removeComboModalLabel"
                             aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title" id="removeComboModalLabel">
                                  {{ currentCombo.name }}
                                </h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Bạn có chắc chắn muốn xóa combo này?</p>
                                <div class="d-grid gap-2">
                                  <button class="btn btn-danger" data-bs-dismiss="modal" type="Button"
                                          v-on:click="deleteCombo">
                                    Xóa combo
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="tab === 'course' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-course"
                   role="tabpanel" aria-labelledby="pills-course-tab">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <table class="table list">
                          <thead class="table-light">
                          <tr>
                            <th scope="col">Tên khóa học</th>
                            <th scope="col">Hiện trang chủ?</th>
                            <th scope="col">Vị trí trang chủ</th>
                            <th scope="col">Học phí</th>
                            <th scope="col">Trạng thái</th>
                            <th scope="col"></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(course, index) in school.courses.data" :key="'course' + index">
                            <td class="align-middle">
                              <div class="d-lg-flex align-items-center">
                                <div class="thumbnail">
                                  <router-link :to="{name: 'CourseDetail', params: {code: course.code}}">
                                    <img :src="course.thumbnail.link" alt="" class="rounded"
                                         v-if="course.thumbnail && course.thumbnail.media_type === 'image'">
                                  </router-link>
                                </div>
                                <div class="ms-lg-3 mt-2 mt-lg-0">
                                  <h4 class="mb-1 text-primary-hover">
                                    <router-link class="text-inherit" :to="{name: 'CourseDetail', params: {code: course.code}}">
                                      {{ course.name }}
                                    </router-link>
                                  </h4>
                                  <span class="fs-6"><span class="text-dark fw-medium">{{course.lesson_count}}</span> bài học</span>
                                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">0</span> học viên</span>
                                </div>
                              </div>
                            </td>
                            <td class="align-middle">
                              <div class="form-check form-switch">
                                <input type="checkbox" v-if="course.show_homepage === 1" checked class="form-check-input" :id="'showHomepage' + course.id">
                                <input type="checkbox" v-if="course.show_homepage === 0" class="form-check-input" :id="'showHomepage' + course.id">
                                <label class="form-check-label" :for="'showHomepage' + course.id"></label>
                              </div>
                            </td>
                            <td class="align-middle">{{ course.homepage_order }}</td>
                            <td class="align-middle">
                              <span class="badge rounded-pill bg-success" v-if="course.fee_type === 0">Miễn phí</span>
                              <span class="badge rounded-pill bg-danger" v-if="course.fee_type === 1">Trả phí</span>
                              <span class="badge rounded-pill bg-primary" v-if="course.fee_type === 2">Theo combo</span>
                            </td>
                            <td class="align-middle">
                              <span class="badge rounded-pill bg-success" v-if="course.status === 1">Mở bán</span>
                              <span class="badge rounded-pill bg-danger" v-if="course.status === 0">Dừng bán</span>
                              <span class="badge rounded-pill bg-primary" v-if="course.status === 2">Nháp</span>
                            </td>
                            <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="courseDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="courseDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                 <router-link class="dropdown-item" :to="{name: 'AdminCourseDetail', params: {code: course.code}}">
                                    <i class="fe fe-settings dropdown-item-icon"></i> Quản trị
                                  </router-link>
                                  <router-link class="dropdown-item" :to="{name: 'CourseDetail', params: {code: course.code}}">
                                    <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                  </router-link>
                                <router-link class="dropdown-item" :to="{name: 'AdminCourseEdit', params: {code: course.code}}">
                                    <i class="fe fe-edit dropdown-item-icon"></i> Sửa
                                  </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentCourse(course)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeCourseModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i> Xóa</a>
                              </span>
                            </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <v-pagination
                            v-if="school.courses.paginatorInfo.lastPage > 1"
                            v-model="school.courses.paginatorInfo.currentPage"
                            :pages="school.courses.paginatorInfo.lastPage"
                            :range-size="1"
                            active-color="#DCEDFF"
                            @update:modelValue="loadSchool"
                        />

                        <div class="modal fade" id="removeCourseModal" tabindex="-1" role="dialog"
                             aria-labelledby="removeCourseModalLabel"
                             aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title" id="removeCourseModalLabel">
                                  {{ currentCourse.name }}
                                </h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Bạn có chắc chắn muốn xóa khóa học này?</p>
                                <div class="d-grid gap-2">
                                  <button class="btn btn-danger" data-bs-dismiss="modal" type="Button"
                                          v-on:click="deleteCourse">
                                    Xóa khóa học
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="tab === 'submit' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-submit"
                   role="tabpanel" aria-labelledby="pills-submit-tab">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <SubmitLesson v-on:dataChange="loadSubmits"
                                  v-on:currentPageChange="currentPage => {submits.paginatorInfo.currentPage = currentPage; loadSubmits()}"
                                  v-on:filterChange="filter => {submitFilter = filter; submits.paginatorInfo.currentPage = 1; loadSubmits()}"
                                  :data="submits.data"
                                  :paginatorInfo="submits.paginatorInfo"/>
                  </div>
                </div>
              </div>
              <div :class="tab === 'user' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-user"
                   role="tabpanel" aria-labelledby="pills-user-tab">
                <div class="col-md-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="userFilter.name" class="form-control me-4" placeholder="Họ tên">
                        <input type="search" v-model="userFilter.email"  class="form-control me-4" placeholder="Email">
                        <input type="search" v-model="userFilter.phone"  class="form-control me-4" placeholder="SĐT">
                        <select class="form-control me-4" v-model="userFilter['school_user.role']">
                          <option value="">-- Vai trò --</option>
                          <option value="admin">Admin</option>
                          <option value="teacher">Giáo viên</option>
                          <option value="pupil">Học viên</option>
                        </select>
                        <select class="form-control" v-model="userFilter['school_user.status']">
                          <option value="">-- Trạng thái --</option>
                          <option value=1>Đang hoạt động</option>
                          <option value=0>Tạm khóa</option>
                        </select>
                      </form>
                    </div>
                    <table class="table mb-4 text-nowrap list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Họ tên</th>
                        <th scope="col">Email/SĐT</th>
                        <th scope="col">Quyền</th>
                        <th scope="col">Khóa free?</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(user, index) in school.users.data" :key="'user' + index">
                        <td class="align-middle">
                          <div class="d-lg-flex align-items-center">
                            <div class="thumbnail">
                              <router-link :to="{name: 'AdminUserDetail', params: {uid: user.uid}}">
                                <img :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="avatar">
                              </router-link>
                            </div>
                            <div class="ms-lg-3 mt-2 mt-lg-0">
                              <h4 class="mb-1 text-primary-hover">
                                <router-link class="text-inherit" :to="{name: 'AdminUserDetail', params: {uid: user.uid}}">
                                  {{ user.name }}
                                </router-link>
                              </h4>
                              <span class="text-inherit">{{ user.created_at }}</span>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span class="d-block">{{ user.email }}</span>
                          <span class="d-block">{{ user.phone }}</span>
                        </td>
                        <td class="align-middle">
                          <a v-on:click="setCurrentChangeUserRoleSchool(user)"
                             href="#" data-bs-toggle="modal"
                             data-bs-target="#changeUserRoleSchoolModal">
                            <span class="badge rounded-pill bg-secondary" v-if="user.userSchoolPivot.role === 'teacher'">Giáo viên</span>
                          </a>
                          <a v-on:click="setCurrentChangeUserRoleSchool(user)"
                             href="#" data-bs-toggle="modal"
                             data-bs-target="#changeUserRoleSchoolModal">
                            <span class="badge rounded-pill bg-danger" v-if="user.userSchoolPivot.role === 'admin'">Admin</span>
                          </a>
                          <a v-on:click="setCurrentChangeUserRoleSchool(user)"
                             href="#" data-bs-toggle="modal"
                             data-bs-target="#changeUserRoleSchoolModal">
                            <span class="badge rounded-pill bg-info" v-if="user.userSchoolPivot.role === 'pupil'">Học viên</span>
                          </a>
                        </td>
                        <td class="align-middle">
                          <div class="form-check form-switch">
                            <input v-on:click="setUserCanUseFreeCourse(user.uid, 0)" type="checkbox" v-if="user.userSchoolPivot.can_use_free_course === 1" checked class="form-check-input" :id="'canUseFreeCourse' + user.uid">
                            <input v-on:click="setUserCanUseFreeCourse(user.uid, 1)" type="checkbox" v-if="user.userSchoolPivot.can_use_free_course === 0" class="form-check-input" :id="'canUseFreeCourse' + user.uid">
                            <label class="form-check-label" :for="'canUseFreeCourse' + user.uid"></label>
                          </div>
                        </td>
                        <td class="align-middle">
                          <span class="badge rounded-pill bg-success" v-if="user.userSchoolPivot.status === 1">Đang hoạt động</span>
                          <span class="badge rounded-pill bg-danger" v-if="user.userSchoolPivot.status === 0">Tạm ngưng</span>
                        </td>
                        <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="userDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <router-link class="dropdown-item" :to="{name: 'AdminUserDetail', params: {uid: user.uid}}">
                                  <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                </router-link>
                                <router-link class="dropdown-item" :to="{name: 'AdminEditUser', params: {uid: user.uid}}">
                                  <i class="fe fe-edit dropdown-item-icon"></i> Sửa
                                </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentChangeUserRoleSchool(user)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#changeUserRoleSchoolModal"><i
                                    class="fe fe-user dropdown-item-icon"></i>Thay đổi quyền</a>
                                <a class="dropdown-item" v-on:click="setCurrentUser(user)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeUserSchoolModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                              </span>
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <v-pagination
                        v-if="school.users.paginatorInfo.lastPage > 1"
                        v-model="school.users.paginatorInfo.currentPage"
                        :pages="school.users.paginatorInfo.lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="loadSchool"
                    />
                  </div>
                </div>
              </div>
              <div :class="tab === 'page' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-page"
                   role="tabpanel" aria-labelledby="pills-page-tab">
                <div class="col-md-12">
                  <div class="card pb-4">
                    <div class="card-header border-bottom-0">
                      <form class="d-flex align-items-center">
                        <input type="search" v-model="pageFilter.name" class="form-control me-4" placeholder="Họ tên">
                        <select class="form-control" v-model="pageFilter.status">
                          <option value="">-- Trạng thái --</option>
                          <option value=1>Đã xuất bản</option>
                          <option value=0>Nháp</option>
                        </select>
                      </form>
                    </div>
                    <table class="table mb-4 text-nowrap list">
                      <thead class="table-light">
                      <tr>
                        <th scope="col">Trang</th>
                        <th scope="col">Lượt xem</th>
                        <th scope="col">Ngày tạo</th>
                        <th scope="col">Trạng thái</th>
                        <th scope="col"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(page, index) in school.pages.data" :key="'page' + index">
                        <td class="align-middle">
                          <div class="d-lg-flex align-items-center">
                            <div class="thumbnail">
                              <router-link :to="{name: 'PageDetail', params: {code: page.code, slug: page.slug}}">
                                <img :src="page.thumbnail ? page.thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="rounded">
                              </router-link>
                            </div>
                            <div class="ms-lg-3 mt-2 mt-lg-0">
                              <h4 class="mb-1 text-primary-hover">
                                <router-link class="text-inherit" :to="{name: 'PageDetail', params: {code: page.code, slug: page.slug}}">
                                  {{ page.name }}
                                </router-link>
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">

                        </td>
                        <td class="align-middle">
                          {{ page.created_at }}
                        </td>
                        <td class="align-middle">
                          <span class="badge rounded-pill bg-success" v-if="page.status === 1">Đã xuất bản</span>
                          <span class="badge rounded-pill bg-danger" v-if="page.status === 0">Nháp</span>
                        </td>
                        <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="pageDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="pageDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <router-link class="dropdown-item" :to="{name: 'PageDetail', params: {code: page.code, slug: page.slug}}">
                                  <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                </router-link>
                                <router-link class="dropdown-item" :to="{name: 'AdminPageEdit', params: {code: page.code}}">
                                  <i class="fe fe-edit dropdown-item-icon"></i> Sửa
                                </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentPage(page)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removePageModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                              </span>
                            </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <v-pagination
                        v-if="school.pages.paginatorInfo.lastPage > 1"
                        v-model="school.pages.paginatorInfo.currentPage"
                        :pages="school.pages.paginatorInfo.lastPage"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="loadSchool"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isAdmin" :class="tab === 'setting' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-setting"
                   role="tabpanel" aria-labelledby="pills-user-tab">
                <div class="col-lg-12 col-md-12 col-12">
                  <div v-for="setting in settings" :key="setting.meta_key" class="mb-4">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                      <h2 class="mb-0">{{ setting.name }}</h2>
                      <button class="btn btn-primary btn-sm float-end" v-on:click="updateSchoolMetaData(setting.meta_key, setting.meta_value)"><i class="fe fe-save"/> Cập nhật</button>
                    </div>
                    <div>
                      <textarea class="form-control" v-model="setting.meta_value"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addUserSchoolModal" tabindex="-1" role="dialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <form v-on:submit.prevent="addUserSchool">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                Thêm người dùng
              </h4>
              <button type="button" id="addUserSchoolModalClose" class="btn-close" data-bs-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-8">
                  <div class="row g-3">
                    <div class="col-md-6">
                      <label for="addUserSchoolName" class="form-label">Họ tên (*)</label>
                      <input v-model="user.name" id="addUserSchoolName" class="form-control" type="text" required/>
                    </div>
                    <div class="col-md-6">
                      <label for="email" class="form-label">Email (*)</label>
                      <input v-model="user.email" v-on:blur="findUserByIdentity(user.email)" id="email" class="form-control" type="email"
                             placeholder="dán email để tự động detect user"
                             required/>
                    </div>
                    <div class="col-md-6">
                      <label for="phone" class="form-label">Số ĐT (*)</label>
                      <input v-model="user.phone" v-on:blur="findUserByIdentity(user.phone)" id="phone" class="form-control" type="text"
                             placeholder="dán sđt để tự động detect user"
                             required/>
                    </div>
                    <div class="col-md-6">
                      <label for="password" class="form-label">Password (*)</label>
                      <input v-model="user.password" id="password" class="form-control" type="text"
                             required/>
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Vai trò (*)</label>
                      <div>
                        <div class="d-inline-flex">
                          <div class="form-check me-3">
                            <input type="radio" id="roleAdmin" v-model="user.role" value="admin"
                                   class="form-check-input">
                            <label class="form-check-label" for="roleAdmin">Admin</label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="roleTeacher" v-model="user.role" value="teacher"
                                   class="form-check-input">
                            <label class="form-check-label" for="roleTeacher">Giáo viên</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" id="rolePupil" v-model="user.role" value="pupil"
                                   class="form-check-input">
                            <label class="form-check-label" for="rolePupil">Học viên</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" v-if="addUserSchoolMessage !== ''">
                      <div class="alert alert-danger" role="alert">
                        {{ addUserSchoolMessage }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <Thumbnail purpose="setThumbnailForUser" v-on:setThumbnail="handleSetThumbnailEvent"
                             :thumbnail="user.thumbnail"/>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button class="btn btn-primary" type="submit">Thêm người dùng</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <button type="button" class="d-none" id="addUserSchoolModalOpen" data-bs-toggle="modal" data-bs-target="#addUserSchoolModal"></button>

    <div class="modal fade" id="changeUserRoleSchoolModal" tabindex="-1" role="dialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <form v-on:submit.prevent="changeUserRoleSchool" class="w-100">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                Thay đổi quyền
              </h4>
              <button type="button" id="changeUserRoleSchoolModalClose" class="btn-close" data-bs-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <label class="form-label">Vai trò (*)</label>
                  <div>
                    <div class="d-inline-flex">
                      <div class="form-check me-3">
                        <input type="radio" id="changeRoleAdmin" v-model="currentChangeUserRoleSchool.userSchoolPivot.role" value="admin"
                               class="form-check-input">
                        <label class="form-check-label" for="changeRoleAdmin">Admin</label>
                      </div>
                      <div class="form-check me-3">
                        <input type="radio" id="changeRoleTeacher" v-model="currentChangeUserRoleSchool.userSchoolPivot.role" value="teacher"
                               class="form-check-input">
                        <label class="form-check-label" for="changeRoleTeacher">Giáo viên</label>
                      </div>
                      <div class="form-check">
                        <input type="radio" id="changeRolePupil" v-model="currentChangeUserRoleSchool.userSchoolPivot.role" value="pupil"
                               class="form-check-input">
                        <label class="form-check-label" for="changeRolePupil">Học viên</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button class="btn btn-primary" type="submit">Thay đổi quyền</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <button type="button" class="d-none" id="changeUserRoleSchoolModalOpen" data-bs-toggle="modal" data-bs-target="#changeUserRoleSchoolModal"></button>

    <div class="modal fade" id="removeUserSchoolModal" tabindex="-1" role="dialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{ currentUser.name }}
            </h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <p>Bạn có chắc muốn xóa người dùng này khỏi trường?</p>
            <div class="d-grid gap-2">
              <button class="btn btn-danger" data-bs-dismiss="modal" type="Button"
                      v-on:click="removeUserSchool">
                Xóa người dùng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="assignUserSchoolModal" tabindex="-1" role="dialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <form v-on:submit.prevent="assignUserSchool" class="w-100">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">
                Phát hiện người dùng
              </h4>
              <button type="button" id="assignUserSchoolModalClose" class="btn-close" data-bs-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="addUserSchoolSuggestUser.uid">
                <div class="row">
                  <div class="col-md-5">
                    <div class="thumbnail">
                      <img :src="addUserSchoolSuggestUser.thumbnail ? addUserSchoolSuggestUser.thumbnail.link : '/assets/images/avatar/avatar.jpg'" class="avatar"/>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <table class="table">
                      <tbody>
                      <tr>
                        <td>Họ tên</td>
                        <td>{{ addUserSchoolSuggestUser.name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ addUserSchoolSuggestUser.email }}</td>
                      </tr>
                      <tr>
                        <td>SĐT</td>
                        <td>{{ addUserSchoolSuggestUser.phone }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="text-center mt-4">
                      <label class="form-label fw-bold">Chọn vai trò (*)</label>
                      <div>
                        <div class="d-inline-flex">
                          <div class="form-check me-3">
                            <input type="radio" id="roleAdmin1" v-model="addUserSchoolSuggestUser.role" value="admin"
                                   class="form-check-input">
                            <label class="form-check-label" for="roleAdmin1">Admin</label>
                          </div>
                          <div class="form-check me-3">
                            <input type="radio" id="roleTeacher1" v-model="addUserSchoolSuggestUser.role" value="teacher"
                                   class="form-check-input">
                            <label class="form-check-label" for="roleTeacher1">Giáo viên</label>
                          </div>
                          <div class="form-check">
                            <input type="radio" id="rolePupil1" v-model="addUserSchoolSuggestUser.role" value="pupil"
                                   class="form-check-input">
                            <label class="form-check-label" for="rolePupil1">Học viên</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button class="btn btn-primary" type="submit">Thêm người dùng</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <button type="button" class="d-none" id="assignUserSchoolModalOpen" data-bs-toggle="modal" data-bs-target="#assignUserSchoolModal"></button>

    <div class="modal fade" id="removePageModal" tabindex="-1" role="dialog"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Xóa trang
            </h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <p>Bạn có chắc chắn muốn xóa trang <strong>{{ currentPage.name }}</strong>?</p>
            <div class="d-grid gap-2">
              <button class="btn btn-danger" data-bs-dismiss="modal" type="Button"
                      v-on:click="deletePage">
                Xóa trang
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Media v-on:setMedia="handleSetMediaEvent" :purpose="mediaPurpose"/>

    <Modal id="messageModal" :type="modalType" :message="modalMessage" :title="modalTitle"
           :message-detail="modalMessageDetail"/>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Thumbnail from "@/components/Thumbnail";
import Media from "@/components/Media";
import Modal from "@/components/Modal";
import Editor from "@/components/Editor";
import {openModal, closeModal, getWhereCondition} from "@/core/services/utils.service";
import SubmitLesson from "@/components/SubmitLesson";
import {getUser} from "@/core/services/jwt.service";

export default {
  name: "SchoolDetail",
  components: {
    Thumbnail,
    Media,
    VPagination,
    Modal,
    Editor,
    SubmitLesson
  },
  data() {
    return {
      school: {
        name: "",
        status: 0,
        content: "",
        thumbnail: {id: 0},
        users: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            total: 0,
            lastPage: 0
          }
        },
        courses: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            total: 0,
            lastPage: 0
          }
        },
        combos: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            total: 0,
            lastPage: 0
          }
        },
        pages: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            total: 0,
            lastPage: 0
          }
        }
      },
      user: {
        uid: "",
        name: "",
        email: "",
        phone: "",
        password: "",
        role: "",
        thumbnail: {id: 0}
      },
      submits: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      submitFilter: {
        name: "",
        email: "",
        phone: "",
        lesson_name: "",
        is_reviewed: ""
      },
      currentCourse: {name: ""},
      currentCombo: {name: ""},
      mediaPurpose: "",
      addUserSchoolMessage: "",
      addUserSchoolSuggestUser: {
        name: "",
        uid: "",
        email: "",
        phone: "",
        role: "pupil"
      },
      assignUserSchoolMessage: "",
      currentSubmit: {comment: "", media: {link: ""}},
      reviewer_id: null,
      assignReviewerSuccess: false,
      reviewSubmitLessonSuccess: false,
      tab: 'about',
      modalMessage: "",
      modalType: "",
      modalMessageDetail: "",
      modalTitle: "",
      settings: {
        header_html: {
          meta_key: "header_html",
          name: "Header HTML",
          meta_value: ""
        },
        footer_html: {
          meta_key: "footer_html",
          name: "Footer HTML",
          meta_value: ""
        },
        ce_robot_ids: {
          meta_key: "ce_robot_ids",
          name: "Mã robot Captain Eye, cách nhau bởi dấu ,",
          meta_value: ""
        },
        home_combo_text: {
          meta_key: "home_combo_text",
          name: "Text combo trang chủ",
          meta_value: ""
        },
        home_course_text: {
          meta_key: "home_course_text",
          name: "Text khóa học trang chủ",
          meta_value: ""
        }
      },
      userFilter: {
        name: "",
        email: "",
        phone: "",
        "school_user.status": "",
        "school_user.role": ""
      },
      currentUser: {
        name: "",
        userSchoolPivot: {
          role: ""
        }
      },
      pageFilter: {
        name: "",
        status: ""
      },
      currentChangeUserRoleSchool: {
        name: "",
        userSchoolPivot: {
          role: ""
        }
      },
      userSchoolCount: {
        admin: 0,
        teacher: 0,
        pupil: 0
      },
      currentPage: {
        name: ""
      }
    }
  },
  methods: {
    loadSchool() {
      let queryName = "";
      let queryNameParam = "";
      if (this.$route.params.code) {
        queryName = "school";
        queryNameParam = `school(code:"${this.$route.params.code}")`;
      } else {
        queryName = 'mySchool';
        queryNameParam = 'mySchool';
      }

      let userFilters = {};

      for (let key in this.userFilter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.userFilter.hasOwnProperty(key)) {
          if (key === "name" || key === "email" || key === "phone") {
            if (this.userFilter[key] !== "") {
              userFilters[key] = {
                operator: "LIKE",
                value: "%" + this.userFilter[key] + "%"
              }
            }
          } else if (key === "school_user.status" || key === "school_user.role") {
            if (this.userFilter[key] !== "") {
              userFilters[key] = {
                operator: "EQ",
                value: this.userFilter[key]
              }
            }
          }
        }
      }

      let userWhere = getWhereCondition(userFilters);

      let query = `query {
        ${queryNameParam} {
          id
          code
          name
          status
          subdomain
          content
          thumbnail_id
          thumbnail {
            id
            link
            media_type
          }
          combos(first: 5, page: ${this.school.combos.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]) {
            data {
              id
              code
              name
              status
              show_homepage
              homepage_order
              content
              created_at
              thumbnail {
                id
                link
                media_type
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
          courses(first: 5, page: ${this.school.courses.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]) {
            data {
              id
              code
              name
              fee_type
              status
              show_homepage
              homepage_order
              section_count
              lesson_count
              created_at
              thumbnail {
                id
                link
                media_type
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
          users(first: 5, page: ${this.school.users.paginatorInfo.currentPage}, orderBy: [{column: "school_user.id", order: DESC}]` + userWhere + `) {
            data {
              id
              uid
              name
              phone
              email
              thumbnail {
                id
                link
                media_type
              }
              userSchoolPivot {
                role
                status
                can_use_free_course
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
          pages(first: 5, page: ${this.school.pages.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]) {
            data {
              id
              code
              name
              slug
              content
              status
              created_at
              thumbnail {
                link
              }
            }
            paginatorInfo {
              total
              lastPage
              currentPage
            }
          }
          metaData(first: 10, where: {AND: [{column: "meta_key", value:["header_html", "footer_html", "ce_robot_ids", "home_combo_text", "home_course_text"], operator: IN}]}) {
            data {
              meta_key
              meta_value
            }
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data[queryName]) {
              this.school = data.data[queryName];
              if (this.school.metaData && this.school.metaData.data) {
                for (let i = 0; i < this.school.metaData.data.length; i++) {
                  this.settings[this.school.metaData.data[i].meta_key].meta_value = this.school.metaData.data[i].meta_value;
                }
              }
              this.getUserSchoolCount();
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    handleSetMediaEvent(media, purpose) {
      if (purpose === "setThumbnailForUser") {
        this.user.thumbnail = media;
      } else if (purpose === "setThumbnailForSchool") {
        this.school.thumbnail = media;
      }
    },
    handleSetThumbnailEvent(purpose) {
      this.mediaPurpose = purpose;
    },
    setRole(role) {
      this.user.role = role;
    },
    findUserByIdentity(identity) {
      let query = `mutation($identity: String!) {
        findUserByIdentity(identity: $identity) {
          uid
          email
          name
          phone
          thumbnail {
            link
          }
        }
      }`;

      ApiService.graphql(query, {identity: identity})
          .then(({data}) => {
            if (data.data && data.data.findUserByIdentity) {
              this.addUserSchoolSuggestUser.name = data.data.findUserByIdentity.name;
              this.addUserSchoolSuggestUser.uid = data.data.findUserByIdentity.uid;
              this.addUserSchoolSuggestUser.email = data.data.findUserByIdentity.email;
              this.addUserSchoolSuggestUser.phone = data.data.findUserByIdentity.phone;
              this.addUserSchoolSuggestUser.thumbnail = data.data.findUserByIdentity.thumbnail;
              openModal("assignUserSchoolModal");
              closeModal("addUserSchoolModal");
            }
          });
    },
    addUserSchool() {
      this.modalTitle = "Thêm người dùng";

      let query = `mutation($role: String!, $name: String!, $phone: String, $email: String!, $password: String!, $thumbnail_id: Int) {
        addUserSchool(role: $role, name: $name, phone: $phone, email: $email, password: $password, thumbnail_id: $thumbnail_id)
      }`;

      ApiService.graphql(query, this.user)
          .then(({data}) => {
            if (data.data && data.data.addUserSchool) {
              this.modalMessage = "Thêm người dùng thành công";
              this.modalType = "success";
              closeModal("addUserSchoolModal");
              openModal("messageModal");
              this.loadSchool();
              this.resetAddUserForm();
            } else {
              this.addUserSchoolMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addUserSchoolMessage = response.message;
          });
    },
    assignUserSchool() {
      this.modalTitle = "Thêm người dùng";

      let query = `mutation($identity: String!, $role: String!) {
        assignUserSchool(role: $role, identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.addUserSchoolSuggestUser.uid, role: this.addUserSchoolSuggestUser.role})
          .then(({data}) => {
            if (data.data && data.data.assignUserSchool) {
              this.modalMessage = "Thêm người dùng thành công";
              this.modalType = "success";
              closeModal("assignUserSchoolModal");
              openModal("messageModal");
              this.loadSchool();
            } else {
              alert(data.errors[0].message);
            }
          })
          .catch((response) => {
            alert(response.message);
          });
    },
    changeUserRoleSchool() {
      this.modalTitle = "Thay đổi quyền";

      let query = `mutation($identity: String!, $role: String!) {
        assignUserSchool(role: $role, identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.currentChangeUserRoleSchool.uid, role: this.currentChangeUserRoleSchool.userSchoolPivot.role})
          .then(({data}) => {
            if (data.data && data.data.assignUserSchool) {
              this.modalMessage = "Thay đổi quyền thành công";
              this.modalType = "success";
              closeModal("changeUserRoleSchoolModal");
              openModal("messageModal");
              this.loadSchool();
            } else {
              if (data.errors) {
                this.modalMessage = data.errors[0].message;
                this.modalType = "danger";
                closeModal("changeUserRoleSchoolModal");
                openModal("messageModal");
              }
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            closeModal("changeUserRoleSchoolModal");
            openModal("messageModal");
          });
    },
    resetAddUserForm() {
      this.user = {
        name: "",
        email: "",
        phone: "",
        password: "",
        role: "",
        thumbnail: {id: 0}
      }
    },
    setCurrentCourse(course) {
      this.currentCourse = course;
    },
    deleteCourse() {
      let query = `mutation {
        deleteCourse(id: ${this.currentCourse.id}) {
          id
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.deleteCourse) {
              this.loadSchool();
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    setCurrentCombo(combo) {
      this.currentCombo = combo;
    },
    deleteCombo() {
      let query = `mutation {
        deleteCombo(id: ${this.currentCombo.id}) {
          id
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.deleteCombo) {
              this.loadSchool();
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadSubmits() {
      let where = this.getSubmitWhereCondition();

      let query = `query {
        submitLessons(first: 5,page: ${this.submits.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}] ${where}) {
          data {
            id
            lesson_name
            section_name
            is_reviewed
            comment
            created_at
            course {
              name
              code
            }
            reviewer {
              name
            }
            media {
              link
            }
            user {
              name
              uid
              thumbnail {
                link
              }
            }
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.submitLessons) {
              this.submits = data.data.submitLessons;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    prepareReviewingSubmitLesson(submit) {
      this.currentSubmit = submit;
      this.currentSubmit.comment = "";
      this.reviewSubmitLessonSuccess = false;
    },
    reviewSubmitLesson() {
      let query = `mutation($id: ID!, $comment: String!) {
        reviewSubmitLesson(id: $id, comment: $comment) {
          id
          comment
          reviewed_at
          is_reviewed
        }
      }`;

      ApiService.graphql(query, this.currentSubmit)
          .then(({data}) => {
            if (data.data && data.data.reviewSubmitLesson) {
              this.reviewSubmitLessonSuccess = true;
              this.loadSubmits();
            } else {
              if (data.errors) {
                alert(data.errors[0].message);
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    prepareAssigningReviewer(submit) {
      this.assignReviewerSuccess = false;
      this.currentSubmit = submit;
      this.reviewer_id = null;
    },
    assignReviewer() {
      let query = `mutation {
        assignReviewer(id: ${this.currentSubmit.id}, reviewer_id: ${this.reviewer_id}) {
          id
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.assignReviewer) {
              this.assignReviewerSuccess = true;
              this.loadSubmits();
            } else {
              alert("fail");
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    handleSetUserCanUseFreeCourse(uid, value) {
      this.setUserCanUseFreeCourse(uid, value);
    },
    setUserCanUseFreeCourse(uid, value) {
      this.modalTitle = "Set quyền được tham gia khóa học miễn phí";

      let query = `mutation($uid: String!, $value: Int!) {
        setUserCanUseFreeCourse(uid: $uid, value: $value)
      }`;

      ApiService.graphql(query, {uid: uid, value: value})
          .then(({data}) => {
            if (data.data && data.data.setUserCanUseFreeCourse) {
              this.loadSchool();
            } else {
              if (data.errors) {
                this.modalMessage = data.errors[0].message;
              }
              this.modalType = "danger";
              window.$("#messageModalOpen").click();
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            window.$("#messageModalOpen").click();
          });
    },
    updateSchoolMetaData(meta_key, meta_value) {
      this.modalTitle = "Cài đặt";

      let query = `mutation($meta_key: String!, $meta_value: String) {
        updateSchoolMetaData(meta_key: $meta_key, meta_value: $meta_value)
      }`;

      ApiService.graphql(query, {meta_key: meta_key, meta_value: meta_value})
          .then(({data}) => {
            if (data.data && data.data.updateSchoolMetaData) {
              this.modalMessage = "Cập nhật thành công";
              this.modalType = "success";
              window.$("#messageModalOpen").click();
            } else {
              if (data.errors) {
                this.modalMessage = data.errors[0].message;
              }
              this.modalType = "danger";
              window.$("#messageModalOpen").click();
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            window.$("#messageModalOpen").click();
          });
    },
    updateSchool() {
      this.modalTitle = "Sửa thông tin trường";

      let query = `mutation($id: ID!, $name: String, $content: String, $thumbnail_id: Int) {
        updateSchool(input: {
          id: $id, name: $name, content: $content, thumbnail_id: $thumbnail_id
        }) {
          id
        }
      }`;

      let thumbnail_id = this.school.thumbnail ? parseInt(this.school.thumbnail.id) : null;

      ApiService.graphql(query, {id: this.school.id, name: this.school.name, content: this.school.content, thumbnail_id: thumbnail_id})
          .then(({data}) => {
            if (data.data && data.data.updateSchool) {
              this.modalMessage = "Cập nhật thông tin trường học thành công";
              this.modalType = "success";
              openModal("messageModal");
              closeModal("updateSchoolModal");
            } else {
              this.modalMessage = data.errors[0].message;
              this.modalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            openModal("messageModal");
          });
    },
    setCurrentUser(user) {
      this.currentUser = user;
    },
    setCurrentChangeUserRoleSchool(user) {
      this.currentChangeUserRoleSchool.uid = user.uid;
      this.currentChangeUserRoleSchool.userSchoolPivot.role = user.userSchoolPivot.role;
    },
    removeUserSchool() {
      this.modalTitle = "Xóa người dùng";

      let query = `mutation($identity: String!) {
        removeUserSchool(identity: $identity)
      }`;

      ApiService.graphql(query, {identity: this.currentUser.uid})
          .then(({data}) => {
            if (data.data && data.data.removeUserSchool) {
              this.modalMessage = "Xóa người dùng thành công";
              this.modalType = "success";
              window.$("#messageModalOpen").click();
              window.$("#removeUserSchoolModalClose").click();
              this.loadSchool();
            } else {
              this.modalMessage = data.errors[0].message;
              this.modalType = "danger";
              window.$("#messageModalOpen").click();
              window.$("#removeUserSchoolModalClose").click();
            }
          })
          .catch((response) => {
            this.modalMessage = response.message;
            this.modalType = "danger";
            window.$("#messageModalOpen").click();
            window.$("#removeUserSchoolModalClose").click();
          });
    },
    getUserSchoolCount() {
      let query = `query($school_code: String) {
        userSchoolCount(school_code: $school_code) {
          admin
          teacher
          pupil
        }
      }`;

      ApiService.graphql(query, {school_code: this.school.code})
          .then(({data}) => {
            if (data.data && data.data.userSchoolCount) {
             this.userSchoolCount = data.data.userSchoolCount;
            }
          });
    },
    backToAddUserSchoolModal() {
      closeModal("assignUserSchoolModal");
      openModal("addUserSchoolModal");
    },
    processTab() {
      let tab = this.$route.query.tab;
      if (tab) {
        this.tab = tab;
      }
    },
    setTab(tab) {
      this.tab = tab;
    },
    getSubmitWhereCondition() {
      let filters = {};

      for (let key in this.submitFilter) {
        if (key === "name" || key === "email" || key === "phone" || key === "lesson_name") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.submitFilter[key] + "%"
            }
          }
        } else if (key === "is_reviewed") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.submitFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    deletePage() {
      let query = `mutation {
        deletePage(id: ${this.currentPage.id}) {
          id
        }
      }`;
      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.deletePage) {
              this.loadSchool();
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
  },
  mounted() {
    this.processTab();
    this.loadSchool();
    this.loadSubmits();
  },
  computed: {
    isAdmin() {
      let user = getUser();
      return user && (user.role === "admin" || user.school_role === "admin");
    }
  },
  watch: {
    userFilter: {
      handler() {
        this.school.users.paginatorInfo.currentPage = 1;
        this.loadSchool();
      },
      deep: true
    },
    pageFilter: {
      handler() {
        this.school.pages.paginatorInfo.currentPage = 1;
        this.loadSchool();
      },
      deep: true
    }
  }
}
</script>
